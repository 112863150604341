<template>
  <v-container class="pa-0">
    <price-card3 v-model="data" :configs="localConfigs" :parentIndex="index" :checkPrice="checkPrice" :checkOrderCount="checkOrderCount" />
  </v-container>
</template>

<script>
import cardMixins from "../mixins";
export default {
  mixins: [cardMixins],
  components: {
    priceCard3: () => import("@/modules/base/components/priceCard/priceCard3.vue"),
  },
  methods: {
    setPrice(configs) {
      const target = configs.find((config) => config.id === "price" );
      if (!target) return;
      if (this.isUnitSame) {
        target.value = this.value[target.id];
        target.valueSuffix = this.priceTextFunc({
          unit: this.shipping_unit,
          showPrice: false
        });
        return;
      }
      target.value = this.value[target.id];
      target.valueSuffix = this.priceTextFunc({
        unit: this.calculate_unit,
        showPrice: false
      });
    },
    setOriginPrice(configs) {
      const target = configs.find((config) => config.id === "origin_price");
      if (!target) return;
      if (this.isUnitSame) {
        target.value = this.value[target.id];
        target.valueSuffix = this.priceTextFunc({
          unit: this.shipping_unit,
          showPrice: false
        });
        return;
      }
      target.value = this.value[target.id];
      target.valueSuffix = this.priceTextFunc({
        unit: this.calculate_unit,
        showPrice: false
      });
    },
    calculateOrderTotal() {
      const config = this.value
      const { order_count, per_shipping_count, price, shipping_unit, modify_count } = config;
      const new_count = this.$helper.transformShippingCount({ count: modify_count != undefined ? modify_count : order_count, unit: shipping_unit })
      let total = null;
      // 出貨單位 = 計價單位
      if (this.isUnitSame) {
        total = Number(new_count) * Number(price);
      } else {
        // 出貨單位 != 計價單位
        total =
          Number(per_shipping_count) * Number(new_count) * Number(price);
      }
      this.orderTotal = isNaN(total) ? null : total;
    },
    calculateTotal(target, config) {
      const { shipping_count, per_shipping_count, price, shipping_unit, modify_count } = config;
      const new_shipping_count = this.$helper.transformShippingCount({ count: modify_count != undefined ? modify_count : shipping_count, unit: shipping_unit })
      let total = null;
      // 出貨單位 = 計價單位
      if (this.isUnitSame) {
        total = Number(new_shipping_count) * Number(price);
      } else {
        // 出貨單位 != 計價單位
        total =
          Number(per_shipping_count) * Number(new_shipping_count) * Number(price);
      }
      target.value = isNaN(total) ? null : total;
      this.total = target.value
    },
  },
  computed: {
    checkPrice() {
      const originPrice = this.value.origin_price
      const currentPrice = this.value.price
      return originPrice == currentPrice
    },
    checkOrderCount() {
      const originCount = this.value.order_count
      const shippingCount = this.value.shipping_count
      return originCount == shippingCount
    },
  },
  mounted() {
    this.calculateOrderTotal()
  },
};
</script>